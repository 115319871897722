import GetStarted from './getStarted'
import IdentificationDocumentsReceived from './identificationDocumentsReceived'
import IdentityVerified from './identityVerified'
import IdentificationFailed from './identificationFailed'

export {
  GetStarted,
  IdentificationDocumentsReceived,
  IdentityVerified,
  IdentificationFailed
}
