import React from 'react'

import { GetStarted } from '../components/modals'

class VerifyAccount extends React.Component {
  render() {
    return(
      <div style={{ backgroundColor: '#f5f5f5' }}>
        <GetStarted />
      </div>
    )
  }
}

export default VerifyAccount
