import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Button
} from 'sqrl-design-system'

class IdentityVerified extends Component {
  render() {
    return (
      <Card style={{ maxWidth: 600 }}>
        <CardHeader>
          <div className="common-modal-header green-heading-style">
            <h1>We’ve verified your identity</h1>
            <p>
              The document you provided was approved and helped to verify you
              and your business identity. You can now continue setting up your
              account.
            </p>
          </div>
        </CardHeader>
        <CardContent style={{ padding: 0 }}>
          <div className="common-modal-content">
            <img
              src="/images/modals/account-verified.svg"
              alt="Document received illustration"
            />
            <h2>Account Verified</h2>
            <p>Your upload was successful!</p>
          </div>
        </CardContent>
        <CardFooter className="get-started-cta" style={{ padding: '20px 0' }}>
          <Button className="is-rounded" isColor="primary">
            Continue
          </Button>
        </CardFooter>
      </Card>
    )
  }
}

export default IdentityVerified
