import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Button
} from 'sqrl-design-system'

class IdentificationFailed extends Component {
  render() {
    return (
      <Card style={{ maxWidth: 600 }}>
        <CardHeader>
          <div className="common-modal-header red-heading-style">
            <h1>We’re unable to verify your identity</h1>
            <p>
              Unfortunately, your account has been temporarily suspended. Please
              reach out to us by email for additional support at&nbsp;
              <a style={{ fontWeight: 600 }} href="mailto:support@eighty4.co">
                support@eighty4.co
              </a>
              .
            </p>
          </div>
        </CardHeader>
        <CardContent style={{ padding: 0 }}>
          <div className="common-modal-content">
            <img
              src="/images/modals/account-suspended.svg"
              alt="Document received illustration"
            />
            <h2>Account Suspended</h2>
            <p>Unfortunately, your account has been temporarily suspended.</p>
          </div>
        </CardContent>
        <CardFooter className="get-started-cta" style={{ padding: '20px 0' }}>
          <Button className="is-rounded" isColor="primary">
            Close
          </Button>
        </CardFooter>
      </Card>
    )
  }
}

export default IdentificationFailed
