import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Button
} from 'sqrl-design-system'

class GetStarted extends Component {
  render() {
    return (
      <Card style={{ maxWidth: 600 }}>
        <CardHeader>
          <div className="common-modal-header teal-heading-style">
            <h1>Verify Your Account</h1>
            <p>
              Thanks for signing up! Let’s finish creating an account by
              following a few easy steps. You’ll need about 5-10 minutes to
              finish these steps.
            </p>
          </div>
        </CardHeader>
        <CardContent style={{ padding: 0 }}>
          <div className="verification-steps">
            <div>
              <h2>Type of Business</h2>
              <p>
                We’ll first need to know if your business is a sole
                proprietorship or a corporation. This will help us tackle the
                next steps appropriately.
              </p>
            </div>
            <div>
              <h2>Personal Verification</h2>
              <p>
                Next, we’ll ask you to verify your personal identity as an
                authorized representative of your company. This step is
                necessary to protect your identity.
              </p>
            </div>
            <div>
              <h2>Business Verification</h2>
              <p>
                Finally, we will collect information to verify your company with
                information such as your Tax ID or EIN. This step is necessary
                to protect your company identity.
              </p>
            </div>
          </div>
          <div className="security-wrapper">
            <div>
              <span style={{ fontSize: 10 }}>
                Your information is encrypted and securely transmitted using
                SSL.
              </span>
            </div>
            <div className="divider">
              <span style={{ fontSize: 10 }}>Protected with</span>
              <span style={{ fontSize: 12, fontWeight: 600 }}>
                Bank-Level Security
              </span>
            </div>
          </div>
        </CardContent>
        <CardFooter className="get-started-cta" style={{ padding: '20px 0' }}>
          <Button className="is-rounded" isColor="primary">
            Get Started
          </Button>
        </CardFooter>
      </Card>
    )
  }
}

export default GetStarted
