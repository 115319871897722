import React, { Component } from 'react'

import {
  Card,
  CardHeader,
  CardContent,
  CardFooter,
  Button
} from 'sqrl-design-system'

class IdentificationDocumentsReceived extends Component {
  render() {
    return (
      <Card style={{ maxWidth: 600 }}>
        <CardHeader>
          <div className="common-modal-header green-heading-style">
            <h1>Identification document received!</h1>
            <p>
              Your upload was successful and we’ve received your identification
              document. Once verified, we’ll get in touch to let you proceed
              setting up your account. It may take up to 1-3 business days to
              review your document and make a decision.
            </p>
          </div>
        </CardHeader>
        <CardContent style={{ padding: 0 }}>
          <div className="common-modal-content">
            <img
              src="/images/modals/documents-received.svg"
              alt="Document received illustration"
            />
            <h2>Document Recieved</h2>
            <p>Your upload was successful! </p>
          </div>
        </CardContent>
        <CardFooter className="get-started-cta" style={{ padding: '20px 0' }}>
          <Button className="is-rounded" isColor="primary">
            Continue
          </Button>
        </CardFooter>
      </Card>
    )
  }
}

export default IdentificationDocumentsReceived
